import React from 'react';
import { MotifSearch, MotifFormField, MotifErrorMessage } from '@ey-xd/motif-react';
import { SEARCH_BAR_PLACEHOLDER } from '../../app.labels';

type SearchBarProps = {
    id?: string;
    className?: string;
    containerClassName?: string;
    hideClearButton?: boolean;
    disabled?: boolean;
    searchButtonTitle?: string;
    value?: string;
    testId?: string;
    placeholder?: string;
    errorMessage?: string;
    items?: (string | number | Record<string, unknown>)[];
    searchButtonProps?: Record<string, unknown>;
    errorClassName?: string;
    maxLength?: number;
    template?: React.ReactNode;
    onEnter?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
    onSelect?: (value: string) => void;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchButtonClick?: (text: string) => void;
    onMouseHover?: (evt: React.MouseEventHandler<HTMLInputElement>) => void;
    onMouseLeave?: (evt: React.MouseEventHandler<HTMLInputElement>) => void;
    onClear?: (evt: React.MouseEventHandler<HTMLInputElement>) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({
    id = '',
    className = '',
    containerClassName = '',
    hideClearButton = false,
    disabled = false,
    searchButtonTitle = SEARCH_BAR_PLACEHOLDER,
    value = '',
    testId = '',
    errorMessage = '',
    placeholder = SEARCH_BAR_PLACEHOLDER,
    items = [],
    searchButtonProps,
    errorClassName = '',
    maxLength = 1000,
    onEnter,
    onSelect,
    onChange,
    onSearchButtonClick,
    onMouseHover,
    onMouseLeave,
    onClear,
    ...other
}) => {
    return (
        <MotifFormField className={containerClassName}>
            <MotifSearch
                id={id}
                className={className}
                hideClearButton={hideClearButton}
                disabled={disabled}
                searchButtonTitle={searchButtonTitle}
                value={value}
                data-test-id={testId}
                placeholder={placeholder}
                items={items}
                searchButtonProps={searchButtonProps}
                maxLength={maxLength}
                aria-label={placeholder}
                onEnter={onEnter}
                onSelect={onSelect}
                onChange={onChange}
                onMouseOver={onMouseHover}
                onMouseLeave={onMouseLeave}
                onClear={onClear}
                {...other}
            />
            {errorMessage && (
                <MotifErrorMessage className={errorClassName}>{errorMessage}</MotifErrorMessage>
            )}
        </MotifFormField>
    );
};

export default SearchBar;
