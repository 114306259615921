import React from 'react';
import { MotifIcon, MotifHeaderLogo } from '@ey-xd/motif-react';
import { defaultLogo } from '@ey-xd/motif-react/assets/images';
import { EY_LOGO_HOME } from '../../app.labels';

const HeaderLogo: React.FC = (): JSX.Element => {
    return (
        <MotifHeaderLogo>
            <a href="/">
                <MotifIcon style={{"float":"left", "pointerEvents":"none"}} src={defaultLogo} aria-hidden="true" className={undefined} title={undefined} />
                <span className="motif-header-seprator">
                    <svg width="2" height="35" viewBox="0 0 2 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.0922852" width="2" height="34.7143" fill="#F6F6FA"/>
                    </svg>
                </span>
                <span className="hide-for-accessibility">{EY_LOGO_HOME}</span>
            </a>
        </MotifHeaderLogo>
    );
};

export default React.memo(HeaderLogo);
  