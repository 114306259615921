import { IAppConfig } from './models/IAppConfig';

let appConfig: IAppConfig;

export const initConfig = (config: IAppConfig): void => {
    appConfig = config;
};

export const setConfig = (key: string, value: any): void => {
    // @ts-ignore
    appConfig[key] = value;
};

export const getConfig = (): IAppConfig => appConfig;
